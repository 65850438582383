
  import Vue from 'vue';
  import { mapMutations, mapState } from 'vuex';
  import { AxiosError } from 'axios';
  import store from '@/store';
  import Files from '@/api/files';
  import User from '@/api/user';
  import FileCard from '@/components/files/FileCard.vue';

  export default Vue.extend({
    name: 'Files',
    components: { FileCard },
    data() {
      return {
        media: undefined as any,
        students: undefined as any,
        filecard_dialog: false,
        tracked_media: {},
      };
    },
    mounted() {
      this.setIsLoadingOverlay(true);
      Files.getFilesOfUser().then((res) => {
        this.media = res;
        this.setIsLoadingOverlay(false);
      });
      this.getStudent();
    },
    computed: {
      ...mapState('app', ['isLoadingOverlay']),
    },
    methods: {
      ...mapMutations('app', ['setIsLoadingOverlay']),
      getStudent(value = 0){ User.students(1, value).then((res: any) => { this.students = res; }) },
      setTrackedMedia(media: any){ this.tracked_media = media; this.filecard_dialog = true;},
      share(ids: []){ this.filecard_dialog = false; Files.shareFiles(ids, this.tracked_media)},
      deleteFile(id: number){this.setIsLoadingOverlay(true); Files.deleteFile(id).then(() => { Files.getFilesOfUser().then((res: any) => { this.media = res; this.setIsLoadingOverlay(false); }); });}
    }
  });
  