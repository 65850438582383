
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
// import MessageCard from '@/components/MessageCard.vue';
// import TodoList from '@/components/TodoList.vue';
// import Calendar from '@/components/Calendar.vue';
import CourseInformation from '@/components/course/CourseInformation.vue';
import courseApi from '@/api/course';
import { Course } from '@/api/interfaces';

export default Vue.extend({
  name: 'Home',
  components: {
    // MessageCard,
    // TodoList,
    // Calendar,
    CourseInformation,
  },
  data: () => ({
    calendarTitle: 'Oktober 2021',
    pagination: {
      page: 1,
      totalPages: 0,
      itemsPerPage: 15,
    },
    courses: [] as Course[],
  }),
  mounted() {
    this.getSubscribedCourses();
  },
  computed: {
    ...mapState('app', ['isLoadingOverlay']),
  },
  methods: {
    ...mapMutations('app', ['setIsLoadingOverlay']),
    calendarPrev() {
      this.calendarTitle = (this.$refs.calendar as any).prev();
    },
    calendarNext() {
      this.calendarTitle = (this.$refs.calendar as any).next();
    },
    getSubscribedCourses() {
      this.setIsLoadingOverlay(true);
      courseApi.getSubscribedCourses().then((res) => {
        this.courses = res.data;

        this.setIsLoadingOverlay(false);
      });
    },
  },
});
