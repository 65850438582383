import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import nl from 'vuetify/src/locale/nl';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0067FF',
        secondary: '#004EC1',
        accent: '#81B3FF',
        error: '#EF5F24',
        info: '#81B3FF',
        success: '#30BC61',
        warning: '#E8AC46',
        background: '#F8F5F5',

        dark: '#2B2B2B',
        light: '#868686',
        lightest: '#C6C6C6',
      },
    },
  },
  lang: {
    locales: { nl },
    current: 'nl',
  },
  icons: {
    iconfont: 'fa',
  },
});
