
  import Vue from 'vue';
  import { mapMutations, mapState } from 'vuex';
  import userApi from '@/api/user';


  export default Vue.extend({
    name: 'OAuth',
    mounted() {
      this.setUserFromOAuth();
      let element = document.getElementsByClassName("logo")[0];
      element.setAttribute('style', 'display:none');

    },
    computed: {
    },
    methods: {
      ...mapMutations('user', ['setUser']),
      async setUserFromOAuth() {
        try {
          console.log(this.$route.query.access_token)

          // Make a call for profile information so we can properly populate the user object
          const user = await userApi.getCurrentUser((this.$route.query.access_token).toString());

          const session = {
              ...user,
              access_token: this.$route.query.access_token,
          };

          // Write session to localstorage
          this.setUser(session);
          localStorage.setItem('eva_user', JSON.stringify(session));
          this.axios.defaults.headers.common = {
            Authorization: `Bearer ${session.access_token}`,
          };

          return this.$router.replace({ name: 'Home' });
        } catch (error) {
          let OAuthStatus = document.getElementById("OAuthStatus");
          if(OAuthStatus != null) {
            OAuthStatus.innerHTML = "Error while getting user, is the token correct?";
          }
        }
      },
    },
    },
  );
  