import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import PasswordForget from '@/views/password/PasswordForget.vue';
import PasswordReset from '@/views/password/PasswordReset.vue';
import OAuth from '@/views/auth/OAuth.vue';
import Invite from '@/views/Invite.vue';

export default [
  {
    path: '/login',
    name: 'Login',
    props: true,
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    props: true,
    component: Register,
  },
  {
    path: '/forget',
    name: 'PasswordForget',
    props: true,
    component: PasswordForget,
  },
  {
    path: '/reset',
    name: 'PasswordReset',
    props: true,
    component: PasswordReset,
  },
  {
    path: '/OAuth',
    name: 'OAuth',
    props: true,
    component: OAuth,
  },
  {
    path: '/invite',
    name: 'Invite',
    props: true,
    component: Invite,
  },
];
