
  import Vue from 'vue';
  import { mapMutations, mapState } from 'vuex';
  import api from '@/api/lesson';
  import { Lesson } from '@/api/interfaces';
  import LessonCard from '@/components/course/LessonCard.vue';

  export default Vue.extend({
    name: 'LessonOverview',
    components: { LessonCard },
    data: () => ({
        incommingLessons: []  as any,
        completedLessons: []  as any,
    }),
    mounted() {
        this.getCompletedLessons();
        this.getAllLessons();
    },
    computed: {
      ...mapState('app', ['isLoadingOverlay']),
    },
    methods: {
        ...mapMutations('app', ['setIsLoadingOverlay']),
        startLesson(lesson: any): void {
            this.$router.push(`/courses/${lesson.course_id}/lessons/${lesson.id}`).catch();
        },
        getAllLessons() {
            this.setIsLoadingOverlay(true);
            api.getAllLessions().then((res: any) => {
                let lessons = []  as any;
                let completed = []  as any;
                res.forEach((course: any) => {
                    if(course.lessons)
                        course.lessons.forEach((lesson: any) => {
                            console.log(Object.keys(this.completedLessons).every((x) => this.completedLessons[x].lesson_id == lesson.id))
                            if(Object.keys(this.completedLessons).every((x) => this.completedLessons[x].lesson_id == lesson.id))
                                completed.push(lesson);
                            else lessons.push(lesson);
                        });
                });
                this.incommingLessons = lessons;
                this.completedLessons = completed;
                this.setIsLoadingOverlay(false);
            });
        },
        getCompletedLessons() {
            this.setIsLoadingOverlay(true);
            api.getCompletedLessons().then((res) => {
                this.completedLessons = res;
                this.setIsLoadingOverlay(false);
            });
        },
    },
  });
  