import { Module } from 'vuex';

export interface AppState {
    isLoading: boolean;
    isLoadingOverlay: boolean;
    isOpenDrawer: boolean;
    isInExam: boolean,
  }

const AppModule: Module<AppState, string> = {
  namespaced: true,
  state: () => ({
    isLoading: true,
    isLoadingOverlay: false,
    isOpenDrawer: false,
    isInExam: false,
  }),
  mutations: {
    setIsLoading(state: AppState, bool: boolean) {
      state.isLoading = bool;
    },
    setIsLoadingOverlay(state: AppState, bool: boolean) {
      state.isLoadingOverlay = bool;
    },
    setIsInExam(state: AppState, bool: boolean) {
      state.isInExam = bool;
    },
    toggleDrawer(state: AppState) {
      state.isOpenDrawer = !state.isOpenDrawer;
    },
    closeDrawer(state: AppState) {
      state.isOpenDrawer = false;
    },
  },
};

export default AppModule;
