import axios from '@/util/axios';
import store from '@/store';
import { Lesson, Paginated } from './interfaces';

export default {
  async getLessons(courseId: number): Promise<Paginated<Lesson[]>> {
    return ((await axios.get<Paginated<Lesson[]>>(`/lesson/${courseId}`)).data as any).data;
  },
  async getLesson(id: number): Promise<Lesson> {
    return (await axios.get<Lesson>(`/lesson/${id}`)).data;
  },
  async getLessonMedia(id: number) {
    return (await axios.get(`/lesson/${id}/media`)).data;
  },
  async completeLesson(id: number): Promise<Lesson> {
    const userId = (store.state as any).user.user.id as number;
    return (await axios.put<Lesson>(`/user/${userId}/lesson/${id}`, {completed_at: new Date()})).data;
  },
  async getCompletedLessons(): Promise<Paginated<Lesson[]>>  {
    const userId = (store.state as any).user.user.id as number;
    return ((await axios.get<Paginated<Lesson[]>>(`/user/${userId}/lesson`)).data as any).data;
  },
  async getAllLessions(): Promise<Paginated<Lesson[]>>  {
    return ((await axios.get<Paginated<Lesson[]>>(`/user/course/lessons`)).data as any).data;
  },
  async uploadLessonFile(id: number, media: object) {
    axios.post<Lesson>(`/lesson/${id}/media`, media, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });

  },
};
