
import Vue from 'vue';
import { mapMutations } from 'vuex';
import { AxiosResponse } from 'axios';
import userApi from '@/api/user';

export default Vue.extend({
  name: 'Invite',
  data() {
    return {
      token:'',
      password: '',
      passwordConfirm: '',
      showPassword: false,
      isRegistring: false,
    };
  },
  mounted() {
    this.populateForm();
  },
  methods: {
    ...mapMutations('user', ['setUser']),
    populateForm() {
      this.token = this.$route.query.token as string;

    },
    async onSubmit() {
      this.isRegistring = true;

      await userApi
        .invite(this.token, this.password)
        .then(async (res) => {
          // Make a call for profile information so we can properly populate the user object
          const user = await userApi.getCurrentUser(res.data.access_token);

          const session = {
            ...user,
            access_token: res.data.access_token,
          };

          // Write session to localstorage
          this.setUser(session);
          localStorage.setItem('eva_user', JSON.stringify(session));
          this.axios.defaults.headers.common = {
            Authorization: `Bearer ${session.access_token}`,
          };

          // Clear form fields
          this.token = '';
          this.password = '';

          return this.$router.replace({ name: 'Home' });
        })
        .catch((error) => {
          this.$toast.error('Token ongeldig');
        })
        .finally(() => {
          this.isRegistring = false;
        });
    },
  },
});
