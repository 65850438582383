
  import Vue from 'vue';
  import { mapMutations, mapState } from 'vuex';
  import store from '@/store';
  import ChatAPI from '@/api/chat';
  import User from '@/api/user';
  import UserCard from '@/components/chats/UserCard.vue';

  export default Vue.extend({
    name: 'Chat',
    components: { UserCard },
    data() {
      return {
        groupname: '' as string,
        groups: [] as any,
        input_file: undefined as any,
        userData: undefined as any,
        chat: [] as any,
        userchats: [] as any,
        ChatDialog: false,
        targetObject: undefined as any,
        userChat:[] as any,
        usercard_dialog: false,
        groupcard_dialog: false,
        message: '' as string,
        users: undefined as any,
        currentChannel: '' as string,
        channelGroup: '' as string,
      };
    },
    mounted() {
        this.setIsLoadingOverlay(true);
        this.userData = (store.state as any).user.user;
        this.getStudent();
        ChatAPI.getGroups().then((res: any) => {this.groups = res.data;});
        ChatAPI.getUserChat().then((res: any) => {this.userchats = res.data; this.setIsLoadingOverlay(false);});
    },
    computed: {
        ...mapState('app', ['isLoadingOverlay']),
    },
    methods: {
        ...mapMutations('app', ['setIsLoadingOverlay']),
        openFile(file: any){location.href = file;},
        getStudent(value = 0){ User.students(1, value).then((res: any) => { this.users = res; }) },
        addUser(user:any){ ChatAPI.addUserToGroup(user.id, this.targetObject.id).then(()=>{ this.usercard_dialog = false; }) },
        createGroupchat(){
            let body = {
                name : this.groupname
            }
            ChatAPI.createGroup(body).then(()=>{
                ChatAPI.getGroups().then((res: any) => {this.groups = res.data;});
                this.groupcard_dialog = false; 
                this.groupname = '';
            })
        },
        startUserChat(student: any){ 
            let users = [this.userData.id, student.id].sort();
            if(this.userchats.length){
                let found = this.userchats.filter((data: any)=>{ return data.channel == `users.${users[0]}.${users[1]}`});
                if(found != null) {
                    this.usercard_dialog = false;
                    return;
                }
            }

            let body ={
                name: this.userData.profile.first_name,
                message: 'Hallo 👋',
                channel: `users.${users[0]}.${users[1]}`,
                receiver: student.id,
            }
            this.setIsLoadingOverlay(true);
            ChatAPI.sendUserChat(body, null).then(() => ChatAPI.getUserChat().then((res: any) => {this.userchats = res.data; this.setIsLoadingOverlay(false); this.usercard_dialog = false;}) );
        },
        listenTo(channel: string, message:string){
            if(this.currentChannel)
                window.Echo.leaveChannel(this.currentChannel);
            this.channelGroup = channel;
            switch(channel) {
                case 'groups':
                    this.currentChannel = 'group.'+this.targetObject.channel;
                    ChatAPI.getGroupChats(this.targetObject.id).then((res: any) => {this.chat = res.data; this.setIsLoadingOverlay(false);});
                    break;
                case 'companies':
                    this.currentChannel = 'company.'+this.targetObject.name.split(' ').join("");
                    ChatAPI.getCompanyChats({company_id: this.targetObject.id, channel: this.currentChannel}).then((res: any) => {this.chat = res.data; this.setIsLoadingOverlay(false);});
                    break;
                case 'users':
                    let users = [this.userData.id, this.targetObject.user.id].sort();
                    this.currentChannel = `user.${users[0]}.${users[1]}`;
                    ChatAPI.getUserChats({channel: this.currentChannel}).then((res: any) => {this.chat = res.data; this.setIsLoadingOverlay(false);});
                    break;
            }
            window.Echo.channel(this.currentChannel)
                .listen(message, (e: any) => {
                    console.log(e)
                    this.chat.push(e);
            });
        },
        sendChat(){
            let header = null;
            let body = {
                name: this.userData.profile.first_name,
                message: this.message,
                channel: this.currentChannel,
                company_id: this.targetObject.id,
                receiver: this.targetObject.user?.id,
                group_chat_id: this.targetObject.id,
            } as any;
            
            if(this.input_file){
                let formData = new FormData();
                Object.keys(body).forEach((key:any) => { formData.append(key, body[key])});
                formData.append('file', this.input_file);
                if(formData.get('receiver') == 'undefined') formData.delete('receiver');
                this.input_file = undefined;
                body = formData;
                header =  {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };
            }
            this.message = ''; 
            switch(this.channelGroup) {
                case 'groups':
                    ChatAPI.sendGroupChat(this.targetObject.id, body, header);
                    return;
                case 'companies':
                    ChatAPI.sendCompanyChat(body, header);
                    return;
                case 'users':
                    ChatAPI.sendUserChat(body, header);
                    return;
            }
        },
        switchToChat(channel: string, message:string, entry: Object = {}){
            this.setIsLoadingOverlay(true);
            this.chat = [];
            this.targetObject = entry;
            this.listenTo(channel, message); 
            this.message = ''; 
            this.input_file = undefined;
            this.ChatDialog = true; 
        },
    },
  });
