
import Vue from 'vue';
import { AxiosResponse } from 'axios';
import userApi from '@/api/user';

export default Vue.extend({
  name: 'Register',
  data() {
    return {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      passwordConfirm: '',
      birthdate: '',
      showPassword: false,
      isRegistring: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isRegistring = true;

      const birthdate = new Date();
      birthdate.setDate(birthdate.getDate() - 2);

      userApi.register({
        first_name: this.first_name, last_name: this.last_name, email: this.email, password: this.password, birthdate: birthdate.toISOString(),
      }).then((res: AxiosResponse) => this.$router.replace({ name: 'Login', query: { redirect: 'registered' } })).catch(() => {
        this.$toast.error('Er is een fout opgetreden, probeer het later opnieuw');
        this.isRegistring = false;
      });
    },
  },
});
