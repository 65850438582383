/* eslint-disable no-shadow */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

function loadModules() {
  const context = require.context('./modules', false, /([a-z_]+)\.(t|j)s$/i);

  const modules = context
    .keys()
    .map((key) => {
      const match = key.match(/([a-z_]+)\.(t|j)s$/i);
      return {
        key,
        name: match && match[1],
      };
    })
    .reduce((modules, { key, name }) => {
      if (name) {
        return {
          ...modules,
          [name]: context(key).default,
        };
      }

      return {};
    }, {});

  return { context, modules };
}

const { context, modules } = loadModules();

const store = new Vuex.Store({
  modules,
});

if (module.hot) {
  module.hot.accept(context.id, () => {
    const { modules } = loadModules();
    store.hotUpdate({
      modules,
    });
  });
}

export default store;
