
import Vue from 'vue';
import { mapMutations } from 'vuex';
import { setInteractionMode } from 'vee-validate';
import userApi from '@/api/user';

setInteractionMode('eager');

export default Vue.extend({
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      isLoggingIn: false,
    };
  },
  methods: {
    ...mapMutations('user', ['setUser']),
    gotoRegister() {
      this.$router.push({
        name: 'Register',
      }).catch(()=>{});
    },
    async OAuthLogin() {
      this.isLoggingIn = true;
      const OAuthResponse = await userApi.getOAuthRedirect()
      console.log(OAuthResponse.url);
      window.location.href = OAuthResponse.url;
      this.isLoggingIn = false;
    },
    async onSubmit() {
      this.isLoggingIn = true;

      await userApi
        .login(this.email, this.password)
        .then(async (res) => {
          // Make a call for profile information so we can properly populate the user object
          const user = await userApi.getCurrentUser(res.data.access_token);

          const session = {
            ...user,
            access_token: res.data.access_token,
          };

          // Write session to localstorage
          this.setUser(session);
          localStorage.setItem('eva_user', JSON.stringify(session));
          this.axios.defaults.headers.common = {
            Authorization: `Bearer ${session.access_token}`,
          };

          // Clear form fields
          this.email = '';
          this.password = '';

          return this.$router.replace({ name: 'Home' });
        })
        .catch((error) => {
          this.$toast.error('Email en/of wachtwoord ongeldig');
        })
        .finally(() => {
          this.isLoggingIn = false;
        });
    },
  },
});
