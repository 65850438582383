
  import Vue from 'vue';
  import { mapMutations, mapState } from 'vuex';
  import { AxiosError } from 'axios';
  import store from '@/store';
  import CertificateAPI from '@/api/certificate';
  import PortfolioAPI from '@/api/portfolio';

  export default Vue.extend({
    name: 'Certificates',
    components: { },
    data() {
      return {
        certificate_name: '' as string,
        portfolio_name: '' as string,
        certificatecard_dialog: false,
        portfoliocard_dialog: false,
        share_dialog: false,
        delete_portfolio: false,
        delete_certificate: false,
        certificates: [] as any,
        portfolios: [] as any,
        input_file: undefined as any,
        target_item: undefined as any,
        link: undefined as any,
        selected: [],
      };
    },
    mounted() {
      PortfolioAPI.getPortfolios().then((res)=>{this.portfolios = res.data});
      CertificateAPI.getCertificates().then((res)=> { this.certificates  = res.data; })
    },
    computed: {
      ...mapState('app', ['isLoadingOverlay']),
    },
    methods: {
      ...mapMutations('app', ['setIsLoadingOverlay']),
      deleteCertificate(){CertificateAPI.deleteCertificate(this.target_item.id).then(()=>{this.certificates = this.certificates.filter((item: any) => {return item.id != this.target_item.id;}); this.delete_certificate = false;})},
      deletePortfolio(){PortfolioAPI.deletePortfolio(this.target_item.id).then(()=>{this.portfolios = this.portfolios.filter((item: any) => {return item.id != this.target_item.id;}); this.delete_portfolio = false;})},
      share(id:number){ PortfolioAPI.share(id).then((res)=>{
        let url = new URL(window.location.origin + '/portfolio');
        let search_params = url.searchParams;
        search_params.set('portfolio', res.data);
        url.search = search_params.toString();
        this.link = url.toString();
        this.share_dialog = true;
      })},
      createPortfolio(){
        const body = {
          name: this.portfolio_name,
          certificate_ids: this.selected.map((item: any)=>item.id),
        };
        PortfolioAPI.addPortfolio(body).then((res)=>{this.portfolios.push(res.data); this.portfoliocard_dialog = false;});
      },
      createCertificate(){
        let body = new FormData();
        body.append('name', this.certificate_name);
        body.append('file', this.input_file);
        CertificateAPI.addCertificate(body).then((res)=>{
          this.certificatecard_dialog = false;
          this.certificate_name = '';
          this.certificates.push(res.data);
          this.input_file = undefined;
        });
      }
    }
  });
  