import axios from '@/util/axios';
import store from '@/store';
import { Paginated } from './interfaces';

export default {
  async getFilesOfUser() {
    return (await axios.get(`/user/media`)).data;
  },
  async shareFiles(user_ids: [], media: Object) {
    return (await axios.post(`/user/media`, {
        "user_ids" : user_ids,
        "media" : media,
    })).data;
  },
  async deleteFile(id: number) {
    return (await axios.delete(`/user/media/${id}`));
  },
};
