import { Module } from 'vuex';
import { Profile, UserSession } from '@/api/user';

export interface UserState {
  user: UserSession | undefined;
}

const storedUser = localStorage.getItem('eva_user');

const UserModule: Module<UserState, string> = {
  namespaced: true,
  state: () => ({
    user: storedUser ? JSON.parse(storedUser) : undefined,
  }),
  getters: {
    isAuthenticated: (state: UserState) => !!state.user,
    fullName: (state: UserState) => {
      if (state.user) {
        return `${state.user.profile.first_name} ${state.user.profile.last_name}`;
      }

      return '';
    },
  },
  mutations: {
    setUser(state: UserState, user: UserState['user']) {
      state.user = user;
    },
    setProfile(state: UserState, profile: Profile) {
      if (!state.user) return;
      state.user.profile = profile;

      // We probably have to update the session cookie
      localStorage.setItem('eva_user', JSON.stringify(state.user));
    },
  },
  actions: {
    async logout() {
      localStorage.removeItem('eva_user');
      window.location.replace('/login');
    },
  },
};

export default UserModule;
