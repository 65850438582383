
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
const logo = require('@/assets/'+process.env?.VUE_APP_THEME_URL +'/logo.png');

export default Vue.extend({
  name: 'Sidebar',
  data: () => ({
    drawerVisible: false,
    logo: logo,
    items: [
      {
        title: 'Home', icon: 'fa-comment-dots', link: '/', disabled: false,
      },
      // {
      //   title: 'Berichten', icon: 'fa-comment-dots', link: '/messages', disabled: true,
      // },
      {
        title: 'Lessen', icon: 'fa-comment-dots', link: '/lessons', disabled: false,
      },
      {
        title: 'Modules', icon: 'fa-comment-dots', link: '/courses', disabled: false,
      },
      {
        title: 'Progressie', icon: 'fa-comment-dots', link: '/progression', disabled: false,
      },
      // {
      //   title: 'Gesprekken', icon: 'fa-comment-dots', link: '/chats', disabled: false,
      // },
      {
        title: 'Mijn Account', icon: 'fa-comment-dots', link: '/profile', disabled: false,
      },
      {
        title: 'Mijn bestanden', icon: 'fa-comment-dots', link: '/files', disabled: false,
      },
      {
        title: 'Mijn Certificates', icon: 'fa-comment-dots', link: '/certificates', disabled: false,
      },
      {
        title: 'Logout', icon: 'fa-comment-dots', link: '/logout', disabled: false,
      },
      // {
      //   title: 'Docenten', icon: 'fa-comment-dots', link: '/lecturers', disabled: true,
      // },
      // {
      //   title: 'Studenten', icon: 'fa-comment-dots', link: '/students', disabled: true,
      // },
      // {
      //   title: 'Vooruitgang', icon: 'fa-comment-dots', link: '/progress', disabled: true,
      // },
      // {
      //   title: 'Certificaten', icon: 'fa-comment-dots', link: '/certificates', disabled: true,
      // },
      // {
      //   title: 'Instellingen', icon: 'fa-comment-dots', link: '/settings', disabled: true,
      // },
    ],
  }),
  mounted() {
    this.drawerVisible = this.$vuetify.breakpoint.mdAndUp;
  },
  methods: {
    ...mapActions('user', ['logout']),
    toggleDrawer() {
      this.drawerVisible = !this.drawerVisible;
    },
    isVisible() {
      return this.drawerVisible;
    },
  },
  computed: {
    ...mapGetters('user', ['fullName']),
  },
});
