
import Vue from 'vue';

export default Vue.extend({
  name: 'UserCard',
  props: {
    students: Object,
  },
  data(){
    return {
        headers: [{ text: 'Email', value: 'email' } ],
        currentPage: this.students.current_page,
        data: this.students.data,
        per_page: this.students.per_page,
        total: this.students.total,
        selected: [],
    }
  },
  methods: {
    execute(data: Object){ return this.$emit('execute', data);  },
    updatePagination(value: number){ return this.$emit('updatePagination', value); },
  },
});
