import axios from '@/util/axios';
import store from '@/store';
import { Paginated } from './interfaces';

export default {
    async addPortfolio(body: Object) {
        return (await axios.post(`/portfolio`, body));
    },
    async getPortfolios() {
        return (await axios.get(`/portfolio`));
    },
    async getPortfolio(portfolio: number) {
        return (await axios.get(`/portfolio/${portfolio}`)).data;
    },
    async deletePortfolio(portfolio: number) {
        return (await axios.delete(`/portfolio/${portfolio}`));
    },
    async share(portfolio: number) {
        return (await axios.post(`/portfolio/${portfolio}/share`));
    },
};
